#contact__header{
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 10rem;

}

#contact__form{
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0% auto;
  margin-top: 2rem;
  margin-bottom: 10rem;

  & > input, 
  &> textarea{
    margin-bottom: 1.4rem;
    padding: .4rem 1rem;
  }

  & > input:focus,
  & > textarea:focus{
    outline: none;
  }

  & > button{
    width: 50%;
    padding: .4rem 1.3rem;
    font-size: 1.3rem;
    cursor:  pointer;
    transition: ease-in-out .4s;

    &:hover{
      background-color: black;
      color: white;
    }
  }
}