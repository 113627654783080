#filter{
    margin-left: 40px;
    margin-top: 5rem;
    padding: .4rem 1rem;
}

#filter-header{
    margin-left: 40px;
    text-transform: uppercase;
}

#video-container{
    display: flex;
    align-items: center ;
    flex-wrap: wrap;

    .video{
        margin-left: 40px;
    }
}