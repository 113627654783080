// BrandGuide for Text: Main text Font: Oldstar

// Main Font Color HEX: 
$main-font-color: #141010;

// Slogan Text Font: Fresh From The Farm

// Slogan Font Color HEX:
$secondary-font-color: #fd1630;

// Symbol Color HEX:
$symbol-color:#ee2d3d;

// Container Color HEX: 
$container-color: #ffffff;